<template>
  <div class="payment-success">
    <div class="success-container">
      <div v-if="isLoading" class="loading-state">
        <div class="spinner"></div>
        <p>Verifying your payment...</p>
      </div>
      <div v-else>
        <div class="success-icon">
          <i class="fa fa-check-circle"></i>
        </div>
        <h1>Payment Successful!</h1>
        <p class="message">Your premium subscription has been activated.</p>
        <div class="features">
          <h3>Premium Features Include:</h3>
          <ul>
            <li><i class="fa fa-star"></i> Unlimited searches</li>
            <li><i class="fa fa-star"></i> Priority processing</li>
            <li><i class="fa fa-star"></i> Uncensored results</li>
            <li><i class="fa fa-star"></i> Source links</li>
          </ul>
        </div>
        <div class="actions">
          <router-link to="/" class="button primary"
            >Go to Homepage</router-link
          >
        </div>
        <div class="support">
          <p>
            Having issues?
            <a href="mailto:support@face-spy.com">Contact Support</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PremiumService from "@/services/PremiumService";
import EventBus from "@/services/EventBus";

export default {
  name: "PaymentSuccess",
  data() {
    return {
      premiumUntil: null,
      isLoading: true,
      error: null,
      retryAttempt: 0,
      maxRetries: 5
    };
  },
  computed: {
    isVerifying() {
      return PremiumService.isInVerificationMode();
    }
  },
  methods: {
    formatPremiumDate(date) {
      return PremiumService.formatPremiumDate(date);
    },
    async loadPremiumStatus() {
      try {
        // Keep in loading state while verification is in progress
        if (this.isVerifying) {
          return;
        }

        this.isLoading = true;
        await PremiumService.loadPremiumStatus();
        this.premiumUntil = PremiumService.getPremiumUntil();
        this.isLoading = false;
      } catch (error) {
        console.error('Failed to load premium status:', error);
        this.error = 'Could not verify premium status';
        this.isLoading = false;
      }
    },
    setupEventListeners() {
      // Listen for premium status changes
      EventBus.on("premium-status-change", (isPremium) => {
        if (isPremium) {
          this.premiumUntil = PremiumService.getPremiumUntil();
          this.isLoading = false;
        }
      });
      
      // Listen for verification state changes
      EventBus.on("premium-verification-change", (isVerifying) => {
        if (!isVerifying) {
          // Verification completed, refresh status
          this.loadPremiumStatus();
        }
      });
    }
  },
  async mounted() {
    // Set up event listeners
    this.setupEventListeners();

    // Process session ID from URL if available
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    
    if (sessionId) {
      try {
        // Process the successful payment
        // console.log('Processing payment with session ID:', sessionId);
        await PremiumService.handleSuccessfulPayment(sessionId);
      } catch (error) {
        // console.error('Error processing payment session:', error);
        this.error = 'Failed to verify payment. Please contact support.';
      }
    }
    
    // Only load premium status if not currently verifying
    if (!this.isVerifying) {
      await this.loadPremiumStatus();
    } else {
      // console.log("Payment verification is in progress...");
    }
  },
  beforeUnmount() {
    // Clean up event listeners
    EventBus.off('premium-status-change');
    EventBus.off('premium-verification-change');
  }
};
</script>

<style scoped>
.payment-success {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  padding: 20px;
  background: linear-gradient(
    135deg,
    rgba(22, 22, 26, 0.9) 0%,
    rgba(16, 16, 22, 0.9) 100%
  );
}

.success-container {
  max-width: 600px;
  width: 100%;
  background-color: rgba(30, 30, 38, 0.85);
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  padding: 40px;
  text-align: center;
  color: white;
}

.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.spinner {
  width: 60px;
  height: 60px;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-top: 5px solid #ffd700;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.success-icon {
  font-size: 80px;
  margin-bottom: 20px;
  color: #4caf50;
}

h1 {
  font-size: 32px;
  margin-bottom: 20px;
  background: linear-gradient(135deg, #ffd700 0%, #ff8c00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.message {
  font-size: 18px;
  margin-bottom: 30px;
}

.details {
  margin: 30px 0;
  font-size: 16px;
}

.highlight {
  font-weight: bold;
  color: #ffd700;
}

.features {
  text-align: left;
  margin: 30px 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.features h3 {
  margin-bottom: 15px;
  font-size: 18px;
}

.features ul {
  list-style-type: none;
  padding: 0;
}

.features li {
  padding: 8px 0;
  display: flex;
  align-items: center;
}

.features li i {
  color: #ffd700;
  margin-right: 10px;
}

.actions {
  margin-top: 30px;
}

.button {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 6px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.primary {
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
}

.primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(74, 116, 220, 0.3);
}

.support {
  margin-top: 30px;
  font-size: 14px;
  opacity: 0.7;
}

.support a {
  color: #4a74dc;
  text-decoration: none;
}

.support a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .success-container {
    padding: 30px 20px;
  }

  .success-icon {
    font-size: 60px;
  }

  h1 {
    font-size: 28px;
  }
}
</style>
